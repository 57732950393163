import React from 'react';

function Loader() {

  return (
    <div className='d-flex justify-content-between align-items-center flex-column vh-100'>
      <div>
        <img style={{ marginTop: 150 }} src="/cib_logo.png" height={60} width="100%" />
      </div>
      <p style={{ fontSize: 16, fontWeight: 500, color: '#0A2851', marginBottom: 150 }}>Đang tải dữ liệu...</p>
    </div>
  )
}
export default Loader;